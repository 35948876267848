import axios from 'axios';
import authHeader from './auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const getAllRole = async () => {
	return axios.get(`${API_URL_DEFAULT}user/role`, { headers: await authHeader() });
};

const getAllDepartment = async () => {
	return axios.get(`${API_URL_DEFAULT}user/all-department`, { headers: await authHeader() });
};

const getUserDeptPos = async (payload) => {
	return axios.get(`${API_URL_DEFAULT}user?${payload}`, { headers: await authHeader() });
};

const getUserBy = async (payload) => {
	return axios.get(`${API_URL_DEFAULT}user/by-dept-div-pos?`, {
		headers: await authHeader(),
		params: payload,
	});
};

const getUserDetail = async (payload) => {
	return axios.get(`${API_URL_DEFAULT}user/get-detail?${payload}`, {
		headers: await authHeader(),
	});
};

const getPosition = async (query) => {
	return axios.get(`${API_URL_DEFAULT}user/get-position`, {
		headers: await authHeader(),
		params: query,
	});
};

export default {
	getAllRole,
	getAllDepartment,
	getUserDeptPos,
	getUserDetail,
	getUserBy,
	getPosition,
};
